import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import queryString from 'query-string'

import Page from "../../components/layout/Page"
import Loading from "../../components/Loading"
import GoogleImage from "../../components/GoogleImage"

import { postData } from "../../services/server"
import { formatPhoneNumber, formatPrice } from "../../utilities/utils"

const Invoice = styled.div`
    table {
        margin-bottom: 20px;
    }
`

const InvoicePage = () => {
  const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "");
  const orderUid = params.uid;

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(false);

  useEffect(
    () => {
        setLoading(true);

        postData("/api/orders/data/invoice", { uid: orderUid },
          function(result) {
			setOrder(result);
          },
          function(error) {
              setError(error);
          },
          function() {
              setLoading(false);
          }
        );
    }, 
    [orderUid]
  );

  useEffect(
    () => {
        if (order) {
            setTimeout(() => {
                window.print(); 
            }, 1000)
        }
    }, 
    [order]
  );

  return (
	<Page>
        {loading ? 
            <Loading />
        : error ? 
            <p style={{color:"red"}}>{error}</p>
        :
            <Invoice>
                <div>
                    <GoogleImage src="logo.png" alt="SignMonkey" style={{ height: "50px" }}/>
                </div>
                <h3>
                    ORDER INVOICE
                </h3>
                <p>
                    Order #: {order.id}<br />
                    Date Placed: {order.order_datetime_formatted}<br />
                </p>

                <table>
                    <thead>
                        <tr>
                            <th colSpan={2}>Item</th>
                        </tr>
                    </thead>
                    <tbody>
                    {order.items.map((item,index) => (
                        <tr key={index}>
                            <td className="preview">
                                <Link to={"/sign/?uid=" + item.uid}>
                                    <GoogleImage 
                                        root={true}
                                        src={item.preview_url}
                                        required 
                                        lazy
                                        style={{
                                            maxHeight: "50px",
                                            maxWidth: "200px"
                                        }}
                                    />
                                </Link>
                            </td>
                            <td className="title">
                                {item.type == "sign" ? 
                                    <div>
                                        <Link to={"/sign/?uid=" + item.uid}>
                                        {item.title}
                                        </Link>
                                    </div>
                                    :
                                    <div dangerouslySetInnerHTML={{__html: item.title}} />
                                }
                                {item.subtitle && 
                                    <div><small>{item.subtitle}</small></div>
                                }
                                {item.type == "sign" && 
                                    <small>
                                    {item.product} - {item.size}
                                    </small>
                                }
                            </td>
  
                        </tr>
                    ))}                    
                    </tbody>
                </table>
                
                <div>Cost: {formatPrice(order.cost)}</div>
                {(order.discount && order.discount > 0) && <div>Discount: -{ formatPrice(order.discount) }</div>}
                {(order.coupon_code && order.coupon_code > 0) && <div>Purchase Code: {order.coupon_code}</div>}
                {(order.coupon_discount && order.coupon_discount > 0) && <div>Purchase Code Amount: -{formatPrice(order.coupon_discount)}</div>}
                <div>Shipping: FREE</div>
                {(order.tax && order.tax > 0) && <div>Tax: -{formatPrice(order.tax)}</div>}
                <div>Total: {formatPrice(order.total)}</div>
                {(order.balance && order.balance > 0) && <div>Balance Remaining: -{formatPrice(order.balance)}</div>}
                
                <h4>CONTACT:</h4>
                Name: {order.user_name}<br />
                Email: {order.user_email}<br />
                Phone: {formatPhoneNumber(order.user_phoneno)}<br />

                <h4>SHIPPING:</h4>
                {order.ship_name}<br />
                {order.ship_company ? order.ship_company : ""}
                <div dangerouslySetInnerHTML={{ __html: order.ship_address1}} />
                {order.ship_address2 ? order.ship_address2 : ""}
                {order.ship_city} {order.ship_state} {order.ship_zipcode}<br />

                <h4>BILLING:</h4>
                {order.billing_name}<br />
                {order.billing_company ? order.billing_company : ""}
                <div dangerouslySetInnerHTML={{ __html: order.billing_address1}} />
                {order.billing_address2 ? order.billing_address2 : ""}
                {order.billing_city} {order.billing_state} {order.billing_zipcode}<br />
            </Invoice>    
        }
    </Page>
  )
}

export default InvoicePage